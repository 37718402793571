import GoBackIcon from '@mui/icons-material/WestRounded';
import { Button, Grid, ThemeProvider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as ErrorImg } from 'shared/assets/images/error.svg';
import theme from 'shared/themes';

const ErrorUnhandled = () => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Grid container height='100vh' alignItems='center'>
        <Grid container item flexDirection='column' alignItems='center' rowSpacing='16px'>
          <Grid item>
            <ErrorImg/>
          </Grid>
          <Grid item>
            <Typography variant='h2'>
              {t('error-boundary.title')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography fontFamily={'Open Sans'}>
              {t('error-boundary.text')}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant='contained' sx={{ padding: '8px 60px' }} onClick={() => window.location.replace('/')}>
              <Typography sx={{ position: 'relative' }}>
                <GoBackIcon sx={{ position: 'absolute', left: '-32px' }}/>
                {t('error-boundary.btns.back')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default ErrorUnhandled;