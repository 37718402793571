import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServerError } from 'shared/types';
import { getProductBundle } from 'shared/utils';
import { addProductTypes, createProduct, deleteProduct, getOneProduct, getProductTypes, getProducts, updateProduct, deleteProductTypes } from 'store/actions/products-action';
import { ProductsState } from 'store/types';

const initialState: ProductsState = {
  bundledProducts: [],
  types: [],
  selectedProduct: null,
  loading: {
    get: false,
    getOne: false,
    create: false,
    delete: false,
    update: false,
    getTypes: false,
    addTypes: false,
    deleteTypes: false,
  },
  error: null,
};

const productsReducer = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setSelectedProduct(state, { payload }: PayloadAction<string>) {
      state.selectedProduct = state.bundledProducts.find((product) => product.name === payload) || null;
    },
  },
  extraReducers: (builder) => {
    builder
      // ============ GET PRODUCTS ============ //
      .addCase(getProducts.pending, (state) => {
        state.loading.get = true;
        state.error = null;
      })
      .addCase(getProducts.fulfilled, (state, { payload }) => {
        state.loading.get = false;
        state.bundledProducts = getProductBundle(payload);
      })
      .addCase(getProducts.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.get = false;
        state.error = action.payload.message;
      })
      // ============ CREATE PRODUCT ============ //
      .addCase(createProduct.pending, (state) => {
        state.loading.create = true;
        state.error = null;
      })
      .addCase(createProduct.fulfilled, (state, { payload }) => {
        state.loading.create = false;
        state.bundledProducts = [...state.bundledProducts, ...getProductBundle(payload)];
      })
      .addCase(createProduct.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.create = false;
        state.error = action.payload.message;
      })
      // ============ DELETE PRODUCT ============ //
      .addCase(deleteProduct.pending, (state) => {
        state.loading.delete = true;
        state.error = null;
      })
      .addCase(deleteProduct.fulfilled, (state, { meta }) => {
        state.loading.delete = false;
        state.bundledProducts = state.bundledProducts.filter((product) => product.name !== meta.arg);
      })
      .addCase(deleteProduct.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.delete = false;
        state.error = action.payload.message;
      })
      // ============ GET ONE PRODUCT ============ //
      .addCase(getOneProduct.pending, (state) => {
        state.loading.getOne = true;
        state.error = null;
        state.selectedProduct = null;
      })
      .addCase(getOneProduct.fulfilled, (state, { payload }) => {
        state.loading.getOne = false;
        state.selectedProduct = getProductBundle(payload)[0];
      })
      .addCase(getOneProduct.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.getOne = false;
        state.error = action.payload.message;
      })
      // ============ UPDATE PRODUCT ============ //
      .addCase(updateProduct.pending, (state) => {
        state.loading.update = true;
        state.error = null;
      })
      .addCase(updateProduct.fulfilled, (state, { payload }) => {
        state.loading.update = false;
        const newProduct = getProductBundle(payload)[0];
        const index = state.bundledProducts.findIndex((product) => product.name === newProduct.name);
        if (index === -1) return;
        state.bundledProducts[index] = newProduct;
      })
      .addCase(updateProduct.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.update = false;
        state.error = action.payload.message;
      })
      // ============ GET PRODUCT TYPES ============ //
      .addCase(getProductTypes.pending, (state) => {
        state.loading.getTypes = true;
        state.error = null;
      })
      .addCase(getProductTypes.fulfilled, (state, { payload }) => {
        state.loading.getTypes = false;
        state.types = payload;
      })
      .addCase(getProductTypes.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.getTypes = false;
        state.error = action.payload.message;
      })
      // ============ ADD PRODUCT TYPES ============ //
      .addCase(addProductTypes.pending, (state) => {
        state.loading.addTypes = true;
        state.error = null;
      })
      .addCase(addProductTypes.fulfilled, (state, { payload }) => {
        state.loading.addTypes = false;
        state.types = [...state.types, payload.pop()];
      })
      .addCase(addProductTypes.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.addTypes = false;
        state.error = action.payload.message;
      })
      // ============ DELETE PRODUCT TYPES ============ //
      .addCase(deleteProductTypes.pending, (state) => {
        state.loading.deleteTypes = true;
        state.error = null;
      })
      .addCase(deleteProductTypes.fulfilled, (state, { meta }) => {
        state.loading.deleteTypes = false;
        state.types = state.types.filter((t) => !meta.arg.includes(t.categoryId))
      })
      .addCase(deleteProductTypes.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.deleteTypes = false;
        state.error = action.payload.message;
      })
  },
});

export const { setSelectedProduct } = productsReducer.actions;
export default productsReducer.reducer;
