export const APP_PATH = {
  SIGN_IN: '/sign-in',
  RESET_PASSWORD: '/sign-in/reset',

  PRODUCTS: '/products',
  PRODUCT_CREATE: '/products/create',
  PRODUCT_DETAILS: '/products/:name',
  PRODUCT_EDIT: '/products/edit/:name',
  PRODUCTS_TYPES: '/products/types',

  USERS: '/users',
  USER_DETAILS: '/users/:id',

  PROFILE: '/profile',

  ORDERS: '/orders',

  ADMINISTRATION: '/administration',

  SUBSCRIPTIONS: '/subscriptions',

  CHAT: '/chat',
};

export const DATE_FORMAT = 'MM/dd/yyyy';
