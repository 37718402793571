import { createAsyncThunk } from '@reduxjs/toolkit';
import repo from 'shared/repository';
import { UserRoles } from 'shared/types';
import { validateServerError } from 'shared/utils';
import { UpdateUserState } from './administration-action';

export const getUsers = createAsyncThunk('users/get', async (types: UserRoles[], { rejectWithValue }) => {
  try {
    const response = await repo.get(`/users?types=${types}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const updateUsers = createAsyncThunk('users/update', async (body: UpdateUserState, { rejectWithValue }) => {
  try {
    const response = await repo.put(`/user/state`, body);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const getSelectedUser = createAsyncThunk('user/get/selected-user', async (id: string, { rejectWithValue }) => {
  try {
    const response = await repo.get(`/user/${id}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const getSelectedParent = createAsyncThunk('user/get/selected-parent', async (id: string, { rejectWithValue }) => {
  try {
    const response = await repo.get(`/users/family-members?userId=${id}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});
