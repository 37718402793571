export interface JwtData {
  userId: string;
  userRole: UserRoles;
  email: string;
  phone?: string;
  created: number;
  updated: number;
}

export enum UserRoles {
  Admin = 'admin',
  Client = 'client',
  Family = 'family'
}

export interface SignInForm {
  email: string;
  password: string;
}

export interface PasswordReset {
  email: string;
}

export interface ServerError {
  message: string;
}

export interface ProductItem {
  id: string;
  name: string;
  displayName: string;
  description: string;
  isAvailable: boolean;
  categories: string[];
  price: number;
  size: ProductSizes;
  quantity: number;
  image: {
    S: string;
  }[];
  created: number;
  updated: number;
}

export interface ProductStock {
  size: string;
  quantity: number;
  isAvailable: boolean;
}

export interface ProductItemBundled {
  name: string;
  displayName: string;
  description: string;
  categories: string[];
  price: number;
  image: {
    S: string;
  }[];
  created: number;
  updated: number;
  stock: ProductStock[];
}

export interface ProductCategoryItem {
  categoryId: string;
  name: string;
  displayName: string;
  created: number;
}

export interface NewProductItemBundled {
  type: string;
  displayName: string;
  description: string;
  categories: string[];
  price: number;
  image: any;
  stock: ProductStock[];
}

export interface UpdateProductItem {
  type: string;
  displayName: string;
  description: string;
  categories: string[];
  price: number;
  image: any;
  stock: ProductStock[];
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export enum FemaleState {
  NONE = 'none',
  PREGNANT = 'pregnant',
  BREASTFEEDING = 'breastfeeding',
}

export enum PhysicalActivities {
  OTHER = 'other',
  LIGHT = 'light',
  SEDENTERY = 'sedentary',
  MODERATE = 'moderate',
  HIGH = 'high',
  EXTREME = 'extreme',
}

export enum PhysicalActivityDuration {
  OTHER = 'other',
  AVERAGE = 'average',
  MODERATE = 'moderate',
  HIGH = 'high',
}

export enum MeasurementType {
  IMP = 'imp',
  MTC = 'mtc',
}

export enum LanguageType {
  ENG = 'eng',
}

export enum CurrencyType {
  US_DOLLAR = 'usDollar',
}

export enum CountryType {
  US = 'us',
  OTHER = 'other',
}

export interface UserSettings {
  measurement: MeasurementType;
  language: LanguageType;
  country: CountryType;
  currency: CurrencyType;
  notificationTime: number;
}

export enum UnitsType {
  WEIGHT,
  LENGTH,
}

export enum ClimateType {
  OTHER = 'other',
  HUMID = 'humid',
  TEMPERATE = 'temperate',
  COLD = 'cold',
}

export enum UserStates {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum SubscriptionInterval {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

export interface UserInfo {
  userId: string;
  created: number;
  updated: number;
  userRole: UserRoles;
  userRoleId: string;
  gender: Gender;
  birth: number;
  firstName: string;
  lastName: string;
  state: UserStates;
  femaleState: FemaleState;
  activity: PhysicalActivities;
  activityDuration: PhysicalActivityDuration;
  email: string;
  phone: string;
  height: number;
  weight: number;
  userVerified: boolean;
  climate: ClimateType;
  settings: UserSettings;
  parentId?: string;
  voucherifyPointsToGo: number;
  voucherifyRedeemedPoints: number;
}

export interface PaginationType {
  page: number;
  perPage: number;
  total: number;
}

export enum ProductSizes {
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = 'xxl',
}

export interface PasswordUpdate {
  currentPassword: string,
  newPassword: string,
  confirmPassword: string,
}

export interface PersonalInfoUpdate {
  birth: number;
  weight: number;
  height: number;
  gender: Gender;
  activity: PhysicalActivities;
  climate: ClimateType;
  activityDuration: PhysicalActivityDuration;
  firstName: string;
  measurement: MeasurementType;
  lastName: string;
}

export type SortDirection = 'asc' | 'desc' | null;

export interface Sort<T> {
  value: T;
  direction: 'asc' | 'desc';
}

export enum OrderStates {
  PREPARING = "preparing",
  SHIPPING = "shipping",
  DELIVERED = "delivered",
  RETURN_REQUESTED = "return-requested",
  CANCELLED_USER = "cancelled-user",
  CANCELLED_ADMIN = "cancelled-admin",
  REFUNDED = "refunded",
}

export enum OrderPaymentStates {
  REQUIRES_PAYMENT_METHOD = "requires_payment_method",
  SUCCEEDED = "succeeded",
  REQUIRES_ACTION = "requires_action",
}

export enum PaymentType {
  CARD = "card",
}

export enum OrderPaymentType {
  CARD = "card",
  GOOGLE_PAY = "google-pay",
  APPLE_PAY = "apple-pay",
}

export enum PaymentMethods {
  Card = "card",
}

export enum CardBrands {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  DINERS = 'diners',
  AMEX = 'amex'
}

export enum DeliveryMethods {
  SELF_PICKUP = "self-pick-up",
  DOOR_DELIVERY = "door-delivery",
}

export enum SubscriptionStatuses {
  ACTIVE = "active",
  PAST_DUE = "past_due",
  CANCELED = "canceled",
  REFUNDED = "refunded",
}

export enum SubscriptionDeliveryTimes {
  EARLY_MORNING = "early-morning",
  MORNING = "morning",
  MIDDAY = "midday",
  AFTERNOON = "afternoon",
}

export interface Address {
  country: string;
  state: string;
  city: string;
  appartment?: string;
  postal_code: string;
  line1: string;
  line2: string;
}

export interface DeliveryDetails {
  name: string;
  phone: string;
  email: string;
  address: Address;
}

export interface CardInfo {
  number: string;
  exp_month: number;
  exp_year: number;
  brand: CardBrands;
}

export interface PaymentMethod {
  created: number;
  updated: number;
  userId: string;
  stripeId: string;
  methodId: string;
  paymentType: PaymentType;
  card: CardInfo;
  billing_details: DeliveryDetails;
}

export interface CartItem {
  userId: string;
  orderId: string;
  productId: string;
  productName: string;
  productDisplayName: string;
  productPrice: number;
  productImage: string;
  productQuantity: number;
  created: number;
  updated: number;
}

export interface OrderItem {
  userId: string;
  orderId: string;
  orderNumber: string;
  orderTotal: number;
  orderState: OrderStates;
  status: string;
  deliveryMethod: DeliveryMethods;
  deliveryDetails: DeliveryDetails;
  paymentMethod: PaymentMethod;
  paymentType: OrderPaymentType;
  cartItems: CartItem[];
  created: number;
  updated: number;
  deliveryPrice: number;
}

export enum AdminPermissionsNames {
  USERS = 'users',
  ORDERS = 'orders',
  PRODUCTS = 'products',
  SUBSCRIPTIONS = 'subscriptions',
  ADMINISTRATION = 'administration',
}

export interface UserRole {
  roleId: string;
  roleName: string;
  roleDisplayName: string;
  roleType: UserRoles;
  rolePermissionsNames: AdminPermissionsNames[];
  created: number;
  updated: number;
}

export interface AddNewAdmin {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
}

export interface SubscriptionRecurring {
  interval_count: number;
  interval: SubscriptionInterval
}

export interface CaseDelivery {
  deliveryPrice: number;
  deliveryName: string;
  recuring: SubscriptionRecurring;
}

export interface SubscriptionItem {
  userId: string;
  created: number;
  updated: number;
  subscriptionNumber: string;
  subscriptionId: string;
  caseId: string;
  paymentMethodId: string;
  priceId: string;
  subscriptionStatus: SubscriptionStatuses;
  deliveryTime: SubscriptionDeliveryTimes;
  deliveryNotes: string;
  deliveryMethod: DeliveryMethods;
  caseAmount: number;
  periodEnd: number;
  subscriptionPrice: number;
  deliveryDetails: DeliveryDetails;
  recurring: SubscriptionRecurring;
  caseDelivery?: CaseDelivery;
}

export interface SubscriptionDelivery {
  deliveryPrice: number;
  deliveryName: string;
  recuring: SubscriptionRecurring;
}

export interface SubscriptionCase {
  groupId: string;
  caseId: string;
  subscriptionPrice: number;
  caseAmount: number;
  caseDelivery: SubscriptionDelivery[];
  created: number;
  updated: number;
}

export interface OrderDeliveryItem {
  deliveryId: string;
  deliveryName: string;
  deliveryDisplayName: string;
  deliveryPrice: number;
  created: number;
  updated: number;
}