import { createSlice } from "@reduxjs/toolkit";
import { ServerError } from "shared/types";
import { addNewUser, deleteUsers, getAdmins, getUserRoles, updateUserRole, updateUserState } from "store/actions/administration-action";
import { AdministrationState } from "store/types";

const initialState : AdministrationState = {
  admins: [],
  roles: [],
  error: null,
  loading: {
    getAdmins: false,
    getRoles: false,
    updateUserRole: false,
    banAdmin: false,
    deleteOneUser: false,
    deleteUsers: false,
    addUser: false,
  }
}

const administrationReducer = createSlice({
  name: 'administration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // ============ GET ADMINS ============ //
      .addCase(getAdmins.pending, (state) => {
        state.loading.getAdmins = true;
        state.error = null;
      })
      .addCase(getAdmins.fulfilled, (state, { payload }) => {
        state.loading.getAdmins = false;
        state.admins = payload;
      })
      .addCase(getAdmins.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.getAdmins = false;
        state.error = action.payload.message;
      })
      // ============ GET ROLES ============ //
      .addCase(getUserRoles.pending, (state) => {
        state.loading.getRoles = true;
        state.error = null;
      })
      .addCase(getUserRoles.fulfilled, (state, { payload }) => {
        state.loading.getRoles = false;
        state.roles = payload;
      })
      .addCase(getUserRoles.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.getRoles = false;
        state.error = action.payload.message;
      })
      // ============ UPDATE USER'S ROLE ============ //
      .addCase(updateUserRole.pending, (state) => {
        state.loading.updateUserRole = true;
        state.error = null;
      })
      .addCase(updateUserRole.fulfilled, (state, { payload }) => {
        state.loading.updateUserRole = false;
        for (let i = 0; i < state.admins.length; i++) {
          if (state.admins[i].userId === payload.userId) {
            state.admins[i] = payload;
            break;
          }
        }
      })
      .addCase(updateUserRole.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.updateUserRole = false;
        state.error = action.payload.message;
      })
      // ============ UPDATE USER'S STATE ============ //
      .addCase(updateUserState.pending, (state) => {
        state.loading.banAdmin = true;
        state.error = null;
      })
      .addCase(updateUserState.fulfilled, (state, { payload }) => {
        state.loading.banAdmin = false;
        for (let i = 0; i < state.admins.length; i++) {
          if (state.admins[i].userId === payload.userId) {
            state.admins[i] = payload;
            break;
          }
        }
      })
      .addCase(updateUserState.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.banAdmin = false;
        state.error = action.payload.message;
      })
      // ============ ADD NEW USER ============ //
      .addCase(addNewUser.pending, (state) => {
        state.loading.addUser = true;
        state.error = null;
      })
      .addCase(addNewUser.fulfilled, (state, { payload }) => {
        state.loading.addUser = false;
        state.admins = [ ...state.admins, payload ];
      })
      .addCase(addNewUser.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.addUser = false;
        state.error = action.payload.message;
      })
      // ============ DELETE USERS ============ //
      .addCase(deleteUsers.pending, (state, { meta }) => {
        if (meta.arg.length === 1) {
          state.loading.deleteOneUser = true;
        } else {
          state.loading.deleteUsers = true;
        }
        state.error = null;
      })
      .addCase(deleteUsers.fulfilled, (state, { meta }) => {
        state.loading.deleteOneUser = false;
        state.loading.deleteUsers = false;
        state.admins = state.admins.filter((admin) => !(meta.arg.includes(admin.userId)));
      })
      .addCase(deleteUsers.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.deleteOneUser = false;
        state.loading.deleteUsers = false;
        state.error = action.payload.message;
      })
  }
})

export default administrationReducer.reducer;