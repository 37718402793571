import { createSlice } from '@reduxjs/toolkit';
import { ServerError, UserInfo, UserRoles } from 'shared/types';
import { getSelectedParent, getSelectedUser, getUsers } from 'store/actions/users-action';
import { UsersState } from 'store/types';
import { updateUsers } from './../actions/users-action';

const initialState: UsersState = {
  users: [],
  familyUsers: [],
  selectedUser: null,
  selectedParents: [],
  error: null,
  loading: {
    table: false,
    update: false,
    getSelectedUser: false,
    getSelectedParent: false,
  },
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSelectedUser(state, { payload }) {
      state.selectedUser = state.users.find((user) => user.userId === payload) || null;
    },
    setSelectedParents(state, { payload }) {
      state.selectedParents = state.familyUsers.filter((user) => user.parentId === payload);
    }
  },
  extraReducers: (builder) => {
    builder
      // ============ GET USERS ============ //
      .addCase(getUsers.pending, (state) => {
        state.loading.table = true;
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.loading.table = false;
        state.users = (payload as UserInfo[]).filter((u) => u.userRole === UserRoles.Client);
        state.familyUsers = (payload as UserInfo[]).filter((u) => u.userRole === UserRoles.Family);
      })
      .addCase(getUsers.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.table = false;
        state.error = action.payload.message;
      })
      // ============ UPDATE USERS ============ //
      .addCase(updateUsers.pending, (state) => {
        state.loading.update = true;
        state.error = null;
      })
      .addCase(updateUsers.fulfilled, (state, { payload }) => {
        const index = state.users.findIndex((user) => user.userId === payload.userId);
        if (index !== -1) {
          state.users[index].state = payload.state;
        }
        state.loading.update = false;
      })
      .addCase(updateUsers.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.update = false;
        state.error = action.payload.message;
      })
      // ============ GET SELECTED USER ============ //
      .addCase(getSelectedUser.pending, (state) => {
        state.loading.getSelectedUser = true;
        state.error = null;
      })
      .addCase(getSelectedUser.fulfilled, (state, { payload }) => {
        state.loading.getSelectedUser = false;
        state.selectedUser = payload
      })
      .addCase(getSelectedParent.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.getSelectedParent = false;
        state.error = action.payload.message;
      })
      // ============ GET SELECTED PARENT ============ //
      .addCase(getSelectedParent.pending, (state) => {
        state.loading.getSelectedParent = true;
        state.error = null;
      })
      .addCase(getSelectedParent.fulfilled, (state, { payload }) => {
        state.loading.getSelectedParent = false;
        state.selectedParents = payload || [];
      })
      .addCase(getSelectedUser.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.getSelectedUser = false;
        state.error = action.payload.message;
      })
    },
});

export const { setSelectedUser, setSelectedParents } = userSlice.actions;
export default userSlice.reducer;
