import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { APP_PATH } from 'app.constants';
import { FunctionComponent, SVGProps, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as AdminIcon } from 'shared/assets/icons/admin-icon.svg';
import { ReactComponent as ProductsIcon } from 'shared/assets/icons/cart-icon.svg';
import { ReactComponent as ChatIcon } from 'shared/assets/icons/chat-icon.svg';
import { ReactComponent as OrdersIcon } from 'shared/assets/icons/orders-icon.svg';
import { ReactComponent as ProfileIcon } from 'shared/assets/icons/profile-icon.svg';
import { ReactComponent as SubsIcon } from 'shared/assets/icons/subs-icon.svg';
import { ReactComponent as UsersIcon } from 'shared/assets/icons/users-icon.svg';
import { colors } from 'shared/themes';
import { AdminPermissionsNames } from 'shared/types';
import { chatCoversationsSelector } from 'store/selectors/chat-selector';
import { userSessionSelector } from 'store/selectors/user-selectors';

interface SidebarNavItem {
  route: string;
  name: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  notificationsCount?: number;
}

const SidebarItem = (props: { navItem: SidebarNavItem }) => {
  const { name, icon: Icon, route, notificationsCount } = props.navItem;
  const location = useLocation();
  const { gray, brand_primary, white, error } = colors.default;
  const isActive = location.pathname.includes(route);
  const navigate = useNavigate();

  return (
      <Grid
        item
        container
        sx={{
          backgroundColor: isActive ? white : '',
          height: '60px',
          borderRadius: '30px',
          flexWrap: 'nowrap',
          cursor: 'pointer',
        }}
        justifyContent={{ xl: 'space-between', lg: 'space-between', md: 'center', sm: 'center' }}
        width={{ xl: '100%', lg: '100%', md: '60px', sm: '60px' }}
        padding={{ xl: '0 15px', lg: '0 10px', md: '0', sm: '0' }}
        onClick={() => navigate(route)}
      >
        <Grid item sx={{ display: 'flex' }}>
          <Grid
            item
            sx={{ alignItems: 'center', display: 'flex', position: 'relative' }}
            marginRight={{ xl: '10px', lg: '8px', md: '0', sm: '0' }}
          >
            <Icon fill={isActive ? brand_primary : gray.dark} />
            {!!notificationsCount && (
              <Grid item sx={{ 
                display: 'flex', 
                bgcolor: error.main, 
                width: '18px',
                height: '18px', 
                alignItems: 'center', 
                justifyContent: 'center',
                borderRadius: '20px', 
                position: 'absolute',
                right: '3px',
                bottom: '12px',
              }}>
                <Typography sx={{ color: white, lineHeight: '12px', fontSize: '12px' }}>
                  {notificationsCount}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item sx={{ alignItems: 'center' }} display={{ xl: 'flex', lg: 'flex', md: 'none', sm: 'none' }}>
            <Typography
              component='h4'
              variant='h4'
              sx={{ color: isActive ? brand_primary : gray.dark, textAlign: 'left' }}
            >
              {name}
            </Typography>
          </Grid>
        </Grid>
        {/* {!!notificationsCount && (
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid item sx={{ display: 'flex', bgcolor: error.main, width: '18px', height: '18px', alignItems: 'center', justifyContent: 'center', borderRadius: '20px' }}>
              <Typography sx={{ color: white, lineHeight: '12px', fontSize: '12px' }}>
                {notificationsCount}
              </Typography>
            </Grid>
          </Grid>
        )} */}
      </Grid>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const { PRODUCTS, USERS, PROFILE, ORDERS, ADMINISTRATION, SUBSCRIPTIONS, CHAT } = APP_PATH;
  const { gray } = colors.default;
  const sesson = useSelector(userSessionSelector);
  const chatConversations = useSelector(chatCoversationsSelector);

  const SIDEBAR_NAVS = useMemo(() => {
    const navs : SidebarNavItem[] = [];

    if (sesson?.permissions?.includes(AdminPermissionsNames.USERS)) {
      navs.push({
        name: t('sidebar.nav.users'),
        route: USERS,
        icon: UsersIcon,
      })
    }

    if (sesson?.permissions?.includes(AdminPermissionsNames.ORDERS)) {
      navs.push({
        name: t('sidebar.nav.orders'),
        route: ORDERS,
        icon: OrdersIcon,
      })
    }

    if (sesson?.permissions?.includes(AdminPermissionsNames.PRODUCTS)) {
      navs.push({
        name: t('sidebar.nav.products'),
        route: PRODUCTS,
        icon: ProductsIcon,
      })
    }

    if (sesson?.permissions?.includes(AdminPermissionsNames.ADMINISTRATION)) {
      navs.push({
        name: t('sidebar.nav.admin'),
        route: ADMINISTRATION,
        icon: AdminIcon,
      })
    }

    if (sesson?.permissions?.includes(AdminPermissionsNames.SUBSCRIPTIONS)) {
      navs.push({
        name: t('sidebar.nav.subs'),
        route: SUBSCRIPTIONS,
        icon: SubsIcon,
      })
    }

    navs.push({
      name: t('sidebar.nav.profile'),
      route: PROFILE,
      icon: ProfileIcon,
    });

    navs.push({
      name: t('sidebar.nav.chat'),
      route: CHAT,
      icon: ChatIcon,
      notificationsCount: chatConversations.reduce((accum, curr) => accum + curr.unreadMessagesCount, 0),
    })

    return navs;  
  }, [sesson?.permissions, chatConversations])

  return (
    <Grid container item sx={{ bgcolor: gray.light, padding: '80px 0', justifyContent: 'center' }}>
      <Grid container item xl={9} lg={11}>
        <Grid container item alignContent='start' justifyContent='center'>
          {SIDEBAR_NAVS.map((nav) => (
            <SidebarItem key={nav.name} navItem={nav} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Sidebar;
