import { createAsyncThunk } from '@reduxjs/toolkit';
import repo from 'shared/repository';
import { PasswordReset, PasswordUpdate, PersonalInfoUpdate, SignInForm, UserRoles } from 'shared/types';
import { validateServerError } from 'shared/utils';

export const signIn = createAsyncThunk('user/signIn', async (form: SignInForm, { rejectWithValue }) => {
  try {
    const response = await repo.post<SignInForm, any>(
      `/users/sign-in?type=${UserRoles.Admin}`, 
      form,
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const resetPassword = createAsyncThunk('user/password/reset', async (values: PasswordReset, { rejectWithValue }) => {
  try {
    const response = await repo.post<PasswordReset, any>(`/users/admin/reset-password`, values);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const updatePassword = createAsyncThunk(
  'user/password/update',
  async (values: PasswordUpdate, { rejectWithValue }) => {
    try {
      const response = await repo.put<PasswordUpdate, any>(`/users/admin/password-update`, values);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(validateServerError(error));
    }
  }
)

export const getProfile = createAsyncThunk(
  'user/get/profile',
  async ( _, { rejectWithValue }) => {
    try {
      const response = await repo.get('/users/profile');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(validateServerError(error));
    }
  }
)

export const updatePersonalInfo = createAsyncThunk(
  'user/update/profile',
  async (data: PersonalInfoUpdate, { rejectWithValue } ) => {
    try {
      const response = await repo.put<PersonalInfoUpdate, any>('/users/personal-information', data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(validateServerError(error));
    }
  }
)

export const getTwilioToken = createAsyncThunk(
  'user/twilio/token/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await repo.get('/users/twilio-token');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(validateServerError(error));
    }
  }
)