import { createSlice } from "@reduxjs/toolkit";
import { ConnectionState } from "@twilio/conversations";
import { sortChatConversations } from "shared/utils";
import { addMessage, getConversations, getMessagesByConversationSid, sendMessage, setConversationMessagesRead } from "store/actions/chat-action";
import { StoreStateI } from "store/types";

export interface ReduxConversation {
  sid: string;
  name: string | null;
  unreadMessagesCount: number;
  lastMessage: {
    created: number | null,
    body: string | null,
  }
}

export interface ReduxMessageMedia {
  url: string | null;
  filename: string | null;
}

export interface ReduxMessage {
  sid: string;
  coversationSid: string;
  author: string | null;
  isAdminMessage: boolean;
  body: string | null;
  created: number | null;
  attachedMedia: ReduxMessageMedia[];
}

export interface ChatState extends StoreStateI {
  conversations: ReduxConversation[];
  selectedCoversation: ReduxConversation | null;
  selectedCoversationMessages: ReduxMessage[];
  connectionState: ConnectionState;
  totalUnreadMessagesCount: number;
}

const initialState: ChatState = {
  selectedCoversation: null,
  selectedCoversationMessages: [],
  conversations: [],
  connectionState: 'disconnected',
  totalUnreadMessagesCount: 0,
  error: null,
  loading: {
    global: false,
    getConversations: false,
    getMessages: false,
    sendMessage: false,
  }
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setConnectionState(state, { payload }) {
      state.connectionState = payload;
    },
    setSelectedCoversation(state, { payload }) {
      state.selectedCoversation = payload;
    },
    clearSelectedCoversationMessages(state) {
      state.selectedCoversationMessages = []
    },
    setChatGlobalLoading(state, { payload }) {
      state.loading.global = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // ================= GET CONVERSATIONS ================= //
      .addCase(getConversations.pending, (state, action) => {
        state.loading.getConversations = true;
        state.error = null;
      })
      .addCase(getConversations.fulfilled, (state, { payload }) => {
        if (!payload) return;
        state.loading.getConversations = false;
        state.conversations = payload as any;
      })
      .addCase(getConversations.rejected, (state, action: any) => {
        state.loading.getConversations = false;
        state.error = action?.payload;
      })
      // ================= GET SELECTED CONVERSATION MESSAGES ================= //
      .addCase(getMessagesByConversationSid.pending, (state, action) => {
        state.loading.getMessages = true;
        state.error = null;
      })
      .addCase(getMessagesByConversationSid.fulfilled, (state, { payload }) => {
        state.loading.getMessages = false;
        state.selectedCoversationMessages = payload || [] as any;
      })
      .addCase(getMessagesByConversationSid.rejected, (state, action: any) => {
        state.loading.getMessages = false;
        state.error = action?.payload;
      })
      // ================= SET COVERSATION MESSAGES READ ================= //
      .addCase(setConversationMessagesRead.fulfilled, (state, { payload, meta }) => {
        if (payload === undefined) return;
        for (let i = 0; i < state.conversations.length; i++) {
          if (state.conversations[i].sid === meta.arg.payload) {
            state.conversations[i].unreadMessagesCount = payload;
            break;
          }
        }
      })
      .addCase(setConversationMessagesRead.rejected, (state, action: any) => {
        state.error = action?.payload;
      })
      // ================= ADD MESSAGE ================= //
      .addCase(addMessage.fulfilled, (state, { payload }) => {
        if (!payload) return;
        if (state?.selectedCoversation?.sid === payload?.coversationSid) {
          state.selectedCoversationMessages.push(payload);
        }
        for (let i = 0; i < state.conversations.length; i++) {
          if (state.conversations[i].sid === payload.coversationSid) {
            if (state?.selectedCoversation?.sid !== payload.coversationSid && !payload.isAdminMessage) {
              state.conversations[i].unreadMessagesCount++;
            }
            state.conversations[i].lastMessage.body = payload.body;
            state.conversations[i].lastMessage.created = payload.created;
            break;
          }
        }
        state.conversations = sortChatConversations(state.conversations);
      })
      .addCase(addMessage.rejected, (state, action: any) => {
        state.error = action?.payload;
      })
      // ================= SEND MESSAGE ================= //
      .addCase(sendMessage.pending, (state) => {
        state.loading.sendMessage = true;
      })
      .addCase(sendMessage.fulfilled, (state) => {
        state.loading.sendMessage = false;
      })
      .addCase(sendMessage.rejected, (state, action: any) => {
        state.loading.sendMessage = false;
        state.error = action?.payload;
      })
  }
})

export default chatSlice.reducer;
export const {
  setConnectionState,
  setSelectedCoversation,
  clearSelectedCoversationMessages,
  setChatGlobalLoading,
} = chatSlice.actions;
