import { createAsyncThunk } from "@reduxjs/toolkit";
import { Client, Message } from "@twilio/conversations";
import { SendToConversation } from "shared/components/chat";
import { sortChatConversations, twilioConversationToReduxConversation, twilioMessageToReduxMessage } from "shared/utils";

interface ChatClientPayload<T = any>{
  client: Client,
  payload: T,
}

export const getConversations = createAsyncThunk('chat/coversations/get', async (data: ChatClientPayload, { rejectWithValue }) => {
  const { client } = data;
  try {
    let paginator = await client.getSubscribedConversations();
    const conversations = paginator.items;
    while (paginator.hasNextPage) {
      paginator = await paginator.nextPage();
      conversations.push(...paginator.items);
    }

    const reduxConversations = await Promise.all(
      conversations.map(async (conv) => twilioConversationToReduxConversation(conv))
    )
    
    return sortChatConversations(reduxConversations);
  } catch (error: any) {
    rejectWithValue(error.message)
  }
})

export const getMessagesByConversationSid = createAsyncThunk('/chat/messages/get', async (data: ChatClientPayload<string>, { rejectWithValue }) => {
  const { client, payload } = data;
  try {

    const conversation = await client.getConversationBySid(payload);
    const total = await conversation.getMessagesCount();
    let paginator = await conversation.getMessages(total);
    let messages = paginator.items;

    while (paginator.hasPrevPage) {
      paginator = await paginator.prevPage();
      messages = [ ...paginator.items, ...messages ]
    }

    const reduxMessages = await Promise.all(
      messages.map(async (message) => twilioMessageToReduxMessage(message))
    )

    return reduxMessages;
  } catch (error: any) {
    rejectWithValue(error.message)
  }
})

export const setConversationMessagesRead = createAsyncThunk('/chat/messages/read', async (data: ChatClientPayload<string>, { rejectWithValue }) => {
  const { client, payload } = data;
  try {
    const conversation = await client.getConversationBySid(payload);
    const unreadCountAfterRead = await conversation.setAllMessagesRead();
    return unreadCountAfterRead;
  } catch (error: any) {
    rejectWithValue(error.message)
  }
})

export const addMessage = createAsyncThunk('/chat/message/add', async (data: ChatClientPayload<Message>, { rejectWithValue }) => {
  const { payload } = data;
  try {
    const reduxMessage = await twilioMessageToReduxMessage(payload);
    if (reduxMessage.isAdminMessage) {
      await payload.conversation.updateLastReadMessageIndex(payload.index);
    }
    return reduxMessage;
  } catch (error: any) {
    rejectWithValue(error.message)
  }
})

export const sendMessage = createAsyncThunk('/chat/message/send', async (data: ChatClientPayload<SendToConversation>, { rejectWithValue }) => {
  const { client, payload: { conversationSid, message } } = data;
  try {
    const conversation = await client.getConversationBySid(conversationSid);
    const builder = conversation.prepareMessage();

    if (message.text) {
      builder.setBody(message.text);
    }

    if (message.files.length) {
      message.files.forEach(async (file) => {
        builder.addMedia({
          filename: file.name,
          contentType: file.type,
          media: file
        })
      })
    }

    builder.buildAndSend();

  } catch (error: any) {
    rejectWithValue(error.message)
  }
})