import { Grid } from '@mui/material';
import Sidebar from '../sidebar';

interface SidebarGridI {
  element: any;
  elementProps?: any;
  hasSidebar?: boolean;
}

const SidebarGrid = (props: SidebarGridI) => {
  const { element: Element, elementProps, hasSidebar } = props;

  return (
    <Grid container display='flex' flexDirection='row'>
      {hasSidebar && (
        <>
          <Grid container item xl={2} lg={2} md={1} sm={1}></Grid>
          <Grid container item xl={2} lg={2} md={1} sm={1} sx={{ height: '100vh', position: 'fixed' }}>
            <Sidebar />
          </Grid>
        </>
      )}
      <Grid container item xl={10} lg={10} md={11} sm={11}>
        <Element {...elementProps} />
      </Grid>
    </Grid>
  );
};

export default SidebarGrid;
