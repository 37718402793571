import { APP_PATH } from 'app.constants';
import axios, { AxiosError } from 'axios';
import cookies from 'js-cookie';

const { REACT_APP_API_URL } = process.env;

const repository = axios.create({
  baseURL: REACT_APP_API_URL,
  withCredentials: true,
  params: {},
});

repository.interceptors.request.use(
  (config) => {
    const access_token = cookies.get('access_token');
    if (access_token) {
      config.headers = {
        Authorization: `Bearer ${access_token}`,
      };
    }
    return config;
  },
);

repository.interceptors.response.use(
  undefined,
  (error: AxiosError) => {
    if (error?.response?.status === 401) {
      cookies.remove('access_token');
      window.location.href = APP_PATH.SIGN_IN;
    }
    if (error?.response?.status === 403 || error?.response?.status === 500) {
      error.response.data = { message: 'errors.oops' };
    }
    return Promise.reject(error);
  },
)

export default repository;
