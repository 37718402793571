import * as firebase from "firebase/app";
// @ts-ignore
import { getMessaging } from "firebase/messaging";

const app = firebase.initializeApp({
  // Project Settings => Add Firebase to your web app
  apiKey: "AIzaSyDom0RpLZtlz9gQV4dT2CcrfZV2zFCDEeg",
  authDomain: "hav-test.firebaseapp.com",
  projectId: "hav-test",
  storageBucket: "hav-test.appspot.com",
  messagingSenderId: "501845721928",
  appId: "1:501845721928:web:6fae1b07994d29bcb9a97d",
});

let messaging = getMessaging(app);
// isSupported().then(() => messaging = getMessaging(app))

export { messaging };
