import { createAsyncThunk } from "@reduxjs/toolkit";
import { AddNewAdmin, UserRoles, UserStates } from "shared/types";
import repo from 'shared/repository';
import { validateServerError } from "shared/utils";

export const getAdmins = createAsyncThunk('admins/get', async (_: void, { rejectWithValue }) => {
  try {
    const response = await repo.get(`/users?types=${UserRoles.Admin}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export const getUserRoles = createAsyncThunk('roles/get', async (types: UserRoles[], { rejectWithValue }) => {
  try {
    const response = await repo.get(`/users/roles?types=${types}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export type UpdateUserRole = { user: { id: string, role: UserRoles }, roleId: string }
export const updateUserRole = createAsyncThunk('role/update', async (body: UpdateUserRole, { rejectWithValue }) => {
  try {
    const response = await repo.put(`/user/role`, body);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export type UpdateUserState = { user: { id: string, role: UserRoles }, userState: UserStates }
export const updateUserState = createAsyncThunk('state/update', async (body: UpdateUserState, { rejectWithValue }) => {
  try {
    const response = await repo.put(`/user/state`, body);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export const addNewUser = createAsyncThunk('user/add', async (body: AddNewAdmin, { rejectWithValue }) => {
  try {
    const response = await repo.post(`/users/admin`, body);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export const deleteUsers = createAsyncThunk('users/delete', async (userIds: string[], { rejectWithValue }) => {
  try {
    const response = await repo.delete(`/users/admins`, { data: { userIds } });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})