import { createAsyncThunk } from "@reduxjs/toolkit";
import repo from 'shared/repository';
import { validateServerError } from "shared/utils";

export interface ChangeOrderStateRequest {
  orderId: string;
  userId: string;
}

export interface CancelAndRefundOrderStateRequest extends ChangeOrderStateRequest {
  amount: number;
}

export const getOreders = createAsyncThunk('/orders/get', async (_, { rejectWithValue }) => {
  try {
    const response = await repo.get(`/orders`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export const cancelAndRefundOrder = createAsyncThunk('/order/refund', async (request: CancelAndRefundOrderStateRequest, { rejectWithValue }) => {
  const { orderId, amount, userId } = request;
  try {
    const response = await repo.post(`/orders/${orderId}/cancel?refundAmount=${amount}`, { userId });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export const confirmOrderShipping = createAsyncThunk('/order/shipping', async (request: ChangeOrderStateRequest, { rejectWithValue }) => {
  const { orderId, userId } = request;
  try {
    const response = await repo.post(`/orders/${orderId}/ship`, { userId });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export const confirmOrderDelivery = createAsyncThunk('/order/delivery', async (request: ChangeOrderStateRequest, { rejectWithValue }) => {
  const { orderId, userId } = request;
  try {
    const response = await repo.post(`/orders/${orderId}/deliver`, { userId });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export const returnOrder = createAsyncThunk('/order/return', async (request: ChangeOrderStateRequest, { rejectWithValue }) => {
  const { orderId, userId } = request;
  try {
    const response = await repo.post(`/orders/${orderId}/return`, { userId });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export const getOrderDeliveries = createAsyncThunk('/order/deliveries/get', async (_, { rejectWithValue }) => {
  try {
    const response = await repo.get(`/order/deliveries`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export type UpdateOrderDeliveryType = { deliveryId: string, deliveryPrice: number }
export const updateOrderDelivery = createAsyncThunk('/order/delivery/update', async (data: UpdateOrderDeliveryType, { rejectWithValue }) => {
  try {
    const response = await repo.put(`/order/delivery`, data);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export type CreateOrderDeliveryType = { deliveryDisplayName: string, deliveryPrice: number }
export const createOrderDelivery = createAsyncThunk('/order/delivery/create', async (data: CreateOrderDeliveryType, { rejectWithValue }) => {
  try {
    const response = await repo.post(`/order/delivery`, data);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})