import { createAsyncThunk } from '@reduxjs/toolkit';
import repo from 'shared/repository';
import { NewProductItemBundled, UpdateProductItem } from 'shared/types';
import { validateServerError } from 'shared/utils';

export const getProducts = createAsyncThunk('products/get', async (_, { rejectWithValue }) => {
  try {
    const response = await repo.get('/products');
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const createProduct = createAsyncThunk(
  'products/create',
  async (product: NewProductItemBundled, { rejectWithValue }) => {
    const formData = new FormData();
    Object.entries(product).forEach(([key, value]) => {
      if (key === 'image') {
        for (let image of product.image) {
          formData.append('image', image);
        }
      }
      else if (key === "stock") {
        formData.append(key, JSON.stringify(value))
      } else {
        formData.append(key, value)
      }
    });
    try {
      const response = await repo.post('/product', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(validateServerError(error));
    }
  },
);

export const deleteProduct = createAsyncThunk('products/delete', async (name: string, { rejectWithValue }) => {
  try {
    const response = await repo.delete(`/product/${name}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const getOneProduct = createAsyncThunk(
  'products/getOne',
  async (name: string, { rejectWithValue }) => {
    try {
      const response = await repo.get(`/product/${name}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(validateServerError(error));
    }
  }
);

export type UpdateProductType = { productName: string, data: UpdateProductItem }
export const updateProduct = createAsyncThunk(
  '/products/update',
  async ({ data, productName }: UpdateProductType, { rejectWithValue }) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'image') {
        for (let image of data.image) {
          formData.append('image', image);
        }
      }
      else if (key === "stock") {
        formData.append(key, JSON.stringify(value))
      } else {
        formData.append(key, value)
      }
    });
    try {
      const response = await repo.put(`/product/${productName}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(validateServerError(error));
    }
  },
);

export const getProductTypes = createAsyncThunk(
  '/products/categories/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await repo.get(`/products/categories`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(validateServerError(error));
    }
  },
);

export const addProductTypes = createAsyncThunk(
  '/products/categories/add',
  async (value: string, { rejectWithValue }) => {
    try {
      const response = await repo.post(`/products/categories`, { categories: [value] });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(validateServerError(error));
    }
  },
);

export const deleteProductTypes = createAsyncThunk(
  '/products/categories/delete',
  async (categoriesIds: string[], { rejectWithValue }) => {
    try {
      const response = await repo.delete(`/products/categories`, { data: { categoriesIds } });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(validateServerError(error));
    }
  },
);