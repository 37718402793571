import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ServerError, SubscriptionCase } from "shared/types";
import { cancelSubscription, getPaymentMethods, getSubs, getSubscriptionsCases, getSubscriptionsCasesDeliveries, refundSubscription, updateSubscriptionsCase, updateSubscriptionsDeliveries } from "store/actions/subscriptions-actions";
import { SubscriptionsState } from "store/types";

const initialState : SubscriptionsState = {
  subscriptions: [],
  subscriptionsCases: [],
  paymentMethods: [],
  deliveries: [],
  deliveryGroupId: null,
  selectedPaymentMethod: null,
  error: null,
  loading: {
    getSubs: false,
    getPMs: false,
    refundSub: false,
    cancelSub: false,
    getDeliveries: false,
    updateDeliveries: false,
    getSubscriptionsCases: false,
    updateSubscriptionsCase: false,
  }
}

const subscriptionsReducer = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSelectedPaymentMethod(state, { payload }: PayloadAction<string>) {
      state.selectedPaymentMethod = state.paymentMethods.find((pm) => pm.methodId === payload) || null;
    }
  },
  extraReducers: (builder) => {
    builder
      // ============= GET SUBS ============= //
      .addCase(getSubs.pending, (state) => {
        state.error = null;
        state.loading.getSubs = true;
      })
      .addCase(getSubs.fulfilled, (state, { payload }) => {
        state.loading.getSubs = false;
        state.subscriptions = payload
      })
      .addCase(getSubs.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.getSubs = false;
        state.error = action.payload.message;
      })
      // ============= GET PAYMENT METHODS ============= //
      .addCase(getPaymentMethods.pending, (state) => {
        state.error = null;
        state.loading.getPMs = true;
      })
      .addCase(getPaymentMethods.fulfilled, (state, { payload }) => {
        state.loading.getPMs = false;
        state.paymentMethods = payload
      })
      .addCase(getPaymentMethods.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.getPMs = false;
        state.error = action.payload.message;
      })
      // ============= REFUND SUBSCRIPTION ============= //
      .addCase(refundSubscription.pending, (state) => {
        state.error = null;
        state.loading.refundSub = true;
      })
      .addCase(refundSubscription.fulfilled, (state, { payload }) => {
        for (let i = 0; i < state.subscriptions.length; i++) {
          if (state.subscriptions[i].subscriptionId === payload.subscriptionId) {
            state.subscriptions[i] = payload;
            break;
          }
        }
        state.loading.refundSub = false;
      })
      .addCase(refundSubscription.rejected, (state, action: any & { payload: ServerError }) => {
        state.error = action.payload.message;
        state.loading.refundSub = false;
      })
      // ============= CANCEL SUBSCRIPTION ============= //
      .addCase(cancelSubscription.pending, (state) => {
        state.error = null;
        state.loading.cancelSub = true;
      })
      .addCase(cancelSubscription.fulfilled, (state, { payload }) => {
        for (let i = 0; i < state.subscriptions.length; i++) {
          if (state.subscriptions[i].subscriptionId === payload.subscriptionId) {
            state.subscriptions[i] = payload;
            break;
          }
        }
        state.loading.cancelSub = false;
      })
      .addCase(cancelSubscription.rejected, (state, action: any & { payload: ServerError }) => {
        state.error = action.payload.message;
        state.loading.cancelSub = false;
      })
      // ============= UPDATE SUBSCRIPTIONS DELIVERIES ============= //
      .addCase(updateSubscriptionsDeliveries.pending, (state) => {
        state.error = null;
        state.loading.updateDeliveries = true;
      })
      .addCase(updateSubscriptionsDeliveries.fulfilled, (state, { payload, meta }) => {
        state.deliveries = meta.arg.caseDelivery;
        state.loading.updateDeliveries = false;
      })
      .addCase(updateSubscriptionsDeliveries.rejected, (state, action: any & { payload: ServerError }) => {
        state.error = action.payload.message;
        state.loading.updateDeliveries = false;
      })
      // ============= GET SUBSCRIPTIONS CASES ============= //
      .addCase(getSubscriptionsCases.pending, (state) => {
        state.error = null;
        state.loading.getSubscriptionsCases = true;
      })
      .addCase(getSubscriptionsCases.fulfilled, (state, { payload }) => {
        state.subscriptionsCases = (payload as SubscriptionCase[])
          .sort((a, b) => a.caseAmount - b.caseAmount)
        state.loading.getSubscriptionsCases = false;
      })
      .addCase(getSubscriptionsCases.rejected, (state, action: any & { payload: ServerError }) => {
        state.error = action.payload.message;
        state.loading.getSubscriptionsCases = false;
      })
      // ============= UPDATE SUBSCRIPTIONS CASE ============= //
      .addCase(updateSubscriptionsCase.pending, (state) => {
        state.error = null;
        state.loading.updateSubscriptionsCase = true;
      })
      .addCase(updateSubscriptionsCase.fulfilled, (state, { payload, meta }) => {
        const newCases = [...state.subscriptionsCases];
        for (let i = 0; i < newCases.length; i++) {
          if (newCases[i].caseId === payload.caseId) {
            newCases[i] = payload;
            break;
          }
        }
        state.subscriptionsCases = newCases;
        state.loading.updateSubscriptionsCase = false;
      })
      .addCase(updateSubscriptionsCase.rejected, (state, action: any & { payload: ServerError }) => {
        state.error = action.payload.message;
        state.loading.updateSubscriptionsCase = false;
      })
      // ============= GET SUBSCRIPTIONS CASES DELIVERIES ============= //
      .addCase(getSubscriptionsCasesDeliveries.pending, (state) => {
        state.error = null;
        state.loading.getDeliveries = true;
      })
      .addCase(getSubscriptionsCasesDeliveries.fulfilled, (state, { payload }) => {
        state.deliveries = payload.deliveries;
        state.deliveryGroupId = payload.groupId;
        state.loading.getDeliveries = false;
      })
      .addCase(getSubscriptionsCasesDeliveries.rejected, (state, action: any & { payload: ServerError }) => {
        state.error = action.payload.message;
        state.loading.getDeliveries = false;
      })
  }
})

export const { setSelectedPaymentMethod } = subscriptionsReducer.actions;
export default subscriptionsReducer.reducer;