import { LinearProgress } from '@mui/material';
import { Client } from '@twilio/conversations';
import { APP_PATH } from 'app.constants';
import { getToken } from "firebase/messaging";
import cookies from "js-cookie";
import React, { FC, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import SidebarGrid from 'shared/components/common/sidebar-grid';
import repo from 'shared/repository';
import { addMessage, getConversations } from 'store/actions/chat-action';
import { getTwilioToken } from 'store/actions/user-action';
import { setChatGlobalLoading, setConnectionState } from 'store/reducers/chat-reducer';
import { userSessionSelector } from 'store/selectors/user-selectors';
import { SessionToken } from 'store/types';
import { messaging } from "./init-fcm";

const { REACT_APP_VAPID_KEY } = process.env;

interface RouteI {
  element: any;
  elementProps?: any;
  session: SessionToken | null;
  hasSidebar?: boolean;
}

// ======= private route ======= //
const PrivateRoute: FC<RouteI> = ({ element: Element, elementProps, session, hasSidebar }) => {
  return session ? (
    <Suspense fallback={<LinearProgress />}>
      <SidebarGrid element={Element} elementProps={elementProps} hasSidebar={hasSidebar} />
    </Suspense>
  ) : (
    <Navigate to={APP_PATH.SIGN_IN} />
  );
};

// ======= public route ======= //
const PublicRoute: FC<RouteI> = ({ element: Element, session }) => (
  <Suspense fallback={<LinearProgress />}>
    {session ? <Navigate to={APP_PATH.USERS} /> : <Element />}
  </Suspense>
);

// ======= pages ======= //
const SignInPage = React.lazy(() => import('./shared/pages/sign-in'));
const ResetPasswordPage = React.lazy(() => import('./shared/pages/reset-password'));
const ProductsPage = React.lazy(() => import('./shared/pages/products'));
const ProductCreatePage = React.lazy(() => import('./shared/pages/product-create'));
const ProductDetailsPage = React.lazy(() => import('./shared/pages/product-details'));
const ProductEditPage = React.lazy(() => import('./shared/pages/product-edit'));
const ProductTypesPage = React.lazy(() => import('./shared/pages/product-types'));
const UsersPage = React.lazy(() => import('./shared/pages/users'));
const UserDetailsPage = React.lazy(() => import('./shared/pages/user-details'));
const ProfilePage = React.lazy(() => import('./shared/pages/profile'));
const OrdersPage = React.lazy(() => import('./shared/pages/orders'));
const AdministrationPage = React.lazy(() => import('./shared/pages/administration'));
const SubscriptionsPage = React.lazy(() => import('./shared/pages/subscriptions'));
const ChatPage = React.lazy(() => import('./shared/pages/chat'));

async function RequestNotificationPermissions() {
  try {
    if (cookies.get("firebase_token")) {
      return;
    }

    const permission = await Notification.requestPermission()
    if (permission !== "granted") {
      cookies.remove("firebase_token", { path: "/", });
      return;
    }

    const token = await getToken(messaging, { vapidKey: REACT_APP_VAPID_KEY, });

    if (!token) {
      cookies.remove("firebase_token", { path: "/", });
      return;
    }

    cookies.set("firebase_token", token, { path: "/", });
    await repo.post('/default/push-token', { pushToken: token })

  } catch (err) {
    console.error(err);
  }
}

const AppRoutes = () => {
  const session = useSelector(userSessionSelector);
  const [chatClient, setChatClient] = useState<Client | null>(null);
  const dispatch = useDispatch();

  const { 
    SIGN_IN, PRODUCTS, PRODUCT_CREATE, PRODUCT_DETAILS, PRODUCT_EDIT, PRODUCTS_TYPES,
    USERS, USER_DETAILS, RESET_PASSWORD, PROFILE, ORDERS, ADMINISTRATION, SUBSCRIPTIONS, CHAT,
  } = APP_PATH;

  useEffect(() => {
    if (!session) return;
    dispatch<any>(getTwilioToken()).then((resonse: any) => {
      const twilio_token = resonse?.payload?.twilio_token;
      if (twilio_token && typeof twilio_token === 'string') {
        setChatClient(new Client(twilio_token));
      }
    })
    RequestNotificationPermissions();
  }, [session]);

  useEffect(() => {
    dispatch<any>(setChatGlobalLoading(true));
    if (!chatClient) return;

    chatClient
      .on('connectionStateChanged', async (state) => {
        dispatch(setConnectionState(state))
        if (state === 'connected') {
          dispatch<any>(setChatGlobalLoading(false));
          dispatch<any>(getConversations({ client: chatClient, payload: undefined }));
        }
      })
      .on('tokenExpired', () => {
        dispatch<any>(getTwilioToken())
      })
      .on('tokenAboutToExpire', () => {
        dispatch<any>(getTwilioToken())
      })
      .on('messageAdded', (message) => {
        dispatch<any>(addMessage({ client: chatClient, payload: message }))
      })

    return () => { chatClient.removeAllListeners() }
  }, [chatClient])

  return (
    <Routes>
      {/* PRIVATE */}
      <Route path={PRODUCTS} element={<PrivateRoute session={session} element={ProductsPage} hasSidebar />} />
      <Route path={PRODUCT_CREATE} element={<PrivateRoute session={session} element={ProductCreatePage} hasSidebar />} />
      <Route path={PRODUCT_DETAILS} element={<PrivateRoute session={session} element={ProductDetailsPage} hasSidebar />} />
      <Route path={PRODUCT_EDIT} element={<PrivateRoute session={session} element={ProductEditPage} hasSidebar />} />
      <Route path={PRODUCTS_TYPES} element={<PrivateRoute session={session} element={ProductTypesPage} hasSidebar />} />
      <Route path={USERS} element={<PrivateRoute session={session} element={UsersPage} hasSidebar />} />
      <Route path={USER_DETAILS} element={<PrivateRoute session={session} element={UserDetailsPage} hasSidebar />} />
      <Route path={PROFILE} element={<PrivateRoute session={session} element={ProfilePage} hasSidebar />} />
      <Route path={ORDERS} element={<PrivateRoute session={session} element={OrdersPage} hasSidebar />} />
      <Route path={ADMINISTRATION} element={<PrivateRoute session={session} element={AdministrationPage} hasSidebar />} />
      <Route path={SUBSCRIPTIONS} element={<PrivateRoute session={session} element={SubscriptionsPage} hasSidebar />} />
      <Route path={CHAT} element={<PrivateRoute session={session} element={ChatPage} elementProps={{ chatClient }} hasSidebar />} />

      {/* PUBLIC */}
      <Route path={SIGN_IN} element={<PublicRoute session={session} element={SignInPage} />} />
      <Route path={RESET_PASSWORD} element={<PublicRoute session={session} element={ResetPasswordPage} />} />

      {/* DEFAULT */}
      <Route path='/' element={<Navigate to={session ? USERS : SIGN_IN} />} />
      <Route path='*' element={<Navigate to={session ? USERS : SIGN_IN} />} />
    </Routes>
  );
};

export default AppRoutes;
