import App from 'app';
import 'index.css';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import * as serviceWorkerRegistration from './service-worker-registration';
import 'shared/i18n';

// ServiceWorkerRegistration.register();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
