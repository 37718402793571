import React from 'react';
import ErrorBoundary from 'shared/components/errors/error-boundary';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import theme from 'shared/themes';
import store from 'store';
import AppRoutes from 'app.routes';

const App = () => {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
